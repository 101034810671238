<template>
  <div class="shipment-status">
    <v-stepper v-model="step" vertical v-if="isMobile">
      <!-- step 1  pending -->
      <v-stepper-step :complete="step > 1" :step="1">
        <span class="font-22 grey--text"> {{ $t("pending") }}</span>
      </v-stepper-step>
      <v-stepper-content :step="1">
        <p class="font-300 font-20 primary--text">
          {{ $t("progress.pending") }}
        </p>
        <v-btn
          class="c-btn mx-1 px-15 mb-3"
          color="secondary"
          height="35"
          max-width="120"
          @click="btnHandler(steps[0])"
        >
          <span class="white--text font-20 font-300">{{
            $t("deleteShipment")
          }}</span>
        </v-btn>
      </v-stepper-content>
      <!-- step 2  agreement -->
      <v-stepper-step :complete="step > 2" :step="2">
        <span class="font-22"> {{ $t("agreementReached") }}</span>
      </v-stepper-step>
      <v-stepper-content :step="2">
        <p class="font-300 font-20 primary--text">
          {{ $t("progress.agreementCompleted") }}
        </p>
        <div class="d-flex align-center">
          <v-btn
            class="c-btn mb-3"
            color="darkgray"
            height="35"
            max-width="120"
            @click="dialogCansl = true"
          >
            <span class="white--text font-20 font-300">{{ $t("cancel") }}</span>
          </v-btn>

          <v-btn
            class="c-btn mx-14 mb-3"
            color="secondary"
            height="35"
            max-width="120"
            @click="paymentStatusHandler"
          >
            <span class="white--text font-20 font-300">{{
              $t("payment")
            }}</span>
          </v-btn>
        </div>
      </v-stepper-content>
      <!-- step 3 payment -->
      <v-stepper-step :complete="step > 3" :step="3">
        <span class="font-22 grey--text">{{ $t("deliveryStage") }}</span>
      </v-stepper-step>
      <v-stepper-content :step="3">
        <p class="font-300 font-20 primary--text">
          {{ $t("progress.pending") }}
        </p>
        <v-btn
          class="c-btn mx-1 mb-3"
          color="secondary"
          height="35"
          max-width="120"
          @click="btnHandler(steps[2])"
        >
          <span class="white--text font-20 font-300">{{
            $t("enterQrCode")
          }}</span>
        </v-btn>
      </v-stepper-content>
      <!-- step 4 in progress-->
      <v-stepper-step :complete="step > 4" :step="4">
        <span class="font-22 grey--text"> {{ $t("InProgress") }}</span>
      </v-stepper-step>
      <v-stepper-content :step="4">
        <p class="font-300 font-20 primary--text">
          {{ $t("progress.inProgress") }}
        </p>
        <v-btn
          class="c-btn mx-1 mb-3"
          color="secondary"
          height="35"
          max-width="120"
          @click="btnHandler(steps[3])"
        >
          <span class="white--text font-20 font-300">{{ $t("showCode") }}</span>
        </v-btn>
      </v-stepper-content>
      <!-- step 5  complete -->
      <v-stepper-step :complete="step > 5" :step="5">
        <span class="font-22 grey--text"> {{ $t("completed") }}</span>
      </v-stepper-step>

      <v-stepper-content :step="5">
        <p class="font-300 font-20 primary--text">
          {{ $t("progress.completed") }}
        </p>
        <v-btn
          class="c-btn mx-1 mb-3"
          color="secondary"
          height="35"
          max-width="120"
          @click="btnHandler(steps[4])"
        >
          <span class="white--text font-20 font-300">{{ $t("rating") }}</span>
        </v-btn>
      </v-stepper-content>
    </v-stepper>

    <v-stepper v-else v-model="step" alt-labels>
      <!-- step 1  pending -->
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" :step="1">
          <span class="font-22 grey--text"> {{ $t("pending") }}</span>
        </v-stepper-step>
        <v-divider></v-divider>

        <!-- step 2  agreement -->
        <v-stepper-step :complete="step > 2" :step="2">
          <span class="font-22"> {{ $t("agreementReached") }}</span>
        </v-stepper-step>
        <v-divider></v-divider>

        <!-- step 3 payment -->
        <v-stepper-step :complete="step > 3" :step="3">
          <span class="font-22 grey--text">{{ $t("deliveryStage") }}</span>
        </v-stepper-step>
        <v-divider></v-divider>

        <!-- step 4 in progress-->
        <v-stepper-step :complete="step > 4" :step="4">
          <span class="font-22 grey--text"> {{ $t("InProgress") }}</span>
        </v-stepper-step>
        <v-divider></v-divider>

        <!-- step 5  complete -->
        <v-stepper-step :complete="step > 5" :step="5">
          <span class="font-22 grey--text"> {{ $t("completed") }}</span>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content :step="1">
          <p class="font-300 font-20 primary--text">
            {{ $t("progress.pending") }}
          </p>
          <v-btn
            class="c-btn mx-1 px-15 mb-3"
            color="secondary"
            height="35"
            min-width="120"
            @click="btnHandler(steps[0])"
          >
            <span class="white--text font-20 font-300">{{
              $t("deleteShipment")
            }}</span>
          </v-btn>
        </v-stepper-content>

        <v-stepper-content :step="2">
          <p class="font-300 font-20 primary--text">
            {{ $t("progress.agreementCompleted") }}
          </p>
          <div class="d-flex align-center">
            <v-btn
              class="c-btn mb-3"
              color="secondary"
              height="35"
              min-width="120"
              @click="dialogCansl = true"
            >
              <span class="white--text font-20 font-300">{{
                $t("cancel")
              }}</span>
            </v-btn>

            <v-btn
              class="c-btn mx-14 mb-3"
              color="secondary"
              height="35"
              min-width="120"
              @click="paymentStatusHandler"
            >
              <span class="white--text font-20 font-300">{{
                $t("payment")
              }}</span>
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content :step="3">
          <p class="font-300 font-20 primary--text">
            {{ $t("progress.pending") }}
          </p>
          <v-btn
            class="c-btn mx-1 mb-3"
            color="secondary"
            height="35"
            min-width="120"
            @click="btnHandler(steps[2])"
          >
            <span class="white--text font-20 font-300">{{
              $t("enterQrCode")
            }}</span>
          </v-btn>
        </v-stepper-content>

        <v-stepper-content :step="4">
          <p class="font-300 font-20 primary--text">
            {{ $t("progress.inProgress") }}
          </p>
          <v-btn
            class="c-btn mx-1 mb-3"
            color="secondary"
            height="35"
            min-width="120"
            @click="btnHandler(steps[3])"
          >
            <span class="white--text font-20 font-300">{{
              $t("showCode")
            }}</span>
          </v-btn>
        </v-stepper-content>

        <v-stepper-content :step="5">
          <p class="font-300 font-20 primary--text">
            {{ $t("progress.completed") }}
          </p>
          <v-btn
            class="c-btn mx-1 mb-3"
            color="secondary"
            height="35"
            min-width="120"
            @click="btnHandler(steps[4])"
          >
            <span class="white--text font-20 font-300">{{ $t("rating") }}</span>
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-dialog v-model="dialogCansl" max-width="400" content-class="white">
      <cancel-pay
        @config="cancelStatusHandler"
        @close="closeHandler"
        @ok="okHandler"
      ></cancel-pay>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="400" content-class="white">
      <component
        :key="keyUpdated"
        @close="closeHandler"
        @ok="okHandler"
        :is="selectedDialog"
        url="/deleteShipmentBySender"
        :id="{ id: item.id, key: 'shipment_id' }"
        :item="item"
        :order_id="item.order_id"
        @codeAdded="step = 4"
      ></component>
    </v-dialog>
  </div>
</template>

<script>
import PlaceBid from "../../../../../components/dialogs/PlaceBid.vue";
import CancelPay from "../../../../../components/dialogs/CancelPay.vue";
export default {
  components: { PlaceBid, CancelPay },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: (vm) => ({
    step: 1,
    steps: [
      {
        title: vm.$t("pending"),
        value: "pending",
        btnTitle: vm.$t("deleteShipment"),
        step: 1,
        dialog: () => import("@/components/dialogs/DeleteShipment.vue"),
      },
      {
        title: vm.$t("agreementReached"),
        value: "aggrement",
        btnTitle: vm.$t("cancel"),
        step: 2,
      },
      {
        title: vm.$t("payment"),
        btnTitle: vm.$t("payment"),
        value: "payment",
        step: 3,
        dialog: () => import("@/components/dialogs/CodeShipment.vue"),
      },
      {
        title: vm.$t("inProgress"),
        value: "in progress",
        btnTitle: vm.$t("enterQRCode"),
        step: 4,
        dialog: () => import("@/components/dialogs/ViewCode.vue"),
      },
      {
        title: vm.$t("completed"),
        value: "completed",
        btnTitle: "",
        step: 5,
        dialog: () => import("@/components/dialogs/RateShipment.vue"),
      },
    ],
    dialog: false,
    dialogCansl: false,
    keyUpdated: false,
    selectedDialog: "",
  }),
  methods: {
    closeHandler() {
      this.dialog = false;
      this.dialogCansl= false;
    },
    okHandler() {
      if (this.step == 1) {
        this.$router.replace("/profile/shipments");
      }
    },
    btnHandler(step) {
      this.dialog = true;
      this.keyUpdated = !this.keyUpdated;
      this.selectedDialog = step.dialog;
    },

    async cancelStatusHandler() {
      let formData = new FormData();
      formData.append("shipment_id", this.item.id);
      let { data } = await this.$axios.post(`cancelShipmentBySender`, formData);
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "success",
        });
        this.$emit("itemUpdated");
        // this.$router.go();
      }else{
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
    async paymentStatusHandler() {
      let formData = new FormData();
      formData.append("order_id", this.item.order_id);
      let { data } = await this.$axios.post(`payWithpaypal`, formData);
      if (data.success) {
        window.location.href = data.data.paypal_link;
      }
    },
  },
  created() {
    let status = this.steps.filter((step) => step.value == this.item.status)[0];
    this.step = status.step;
  },
};
</script>

<style lang="scss">
</style>
