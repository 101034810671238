<template>
    <div class="delete-dialog">
      <div class="px-10 text-center">
        <span class="mt-10 d-block font-20 black--text font-500 mb-10">
          {{$t('dialogs.areYouSureCancel')}}</span
        >
        <div class="mb-10">
          <v-btn
            class="c-btn mx-1"
            color="grey"
            height="40"
            @click="$emit('close')"
            ><span class="white--text font-300 font-20 d-inline-block px-7"
              >{{$t('no')}}</span
            ></v-btn
          >
          <v-btn
            class="c-btn mx-1"
            color="primary"
            height="40"
            @click="$emit('config')"
            ><span class="white--text font-300 font-20 d-inline-block px-7"
              >{{$t('yes')}}</span
            ></v-btn
          >
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      dialog: {
        type: Boolean,
        default: false,
      },
      url: {
        type: String,
        default: "",
      },
      id: {
        type: String,
        default: "",
      },
      order_id:{
        type:Number,
      }
    },
    methods: {

    },
  };
  </script>
  
  <style lang="scss"></style>
  